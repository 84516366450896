<template>
  <div>
    <div v-if="loading" class="col-12 text-center">
      <div
        class="m-loader m-loader--danger"
        style="width: 30px; display: inline-block"
      ></div>
    </div>
    <div v-else class="bd-example bd-example-images">
      <a href="#image-service">
        <img
          v-if="!file && !imageUrl"
          class="img-thumbnail cursor-pointer"
          :src="displays[type].thumbnail"
          data-holder-rendered="true"
          @click="pickFile"
          :alt="`${width}x${height}`"
          :style="`width: ${width}px; height: ${height}px; display: block`"
        />

        <img
          v-else
          class="img-thumbnail cursor-pointer"
          :src="imageUrl ? imageUrl : file.url"
          data-holder-rendered="true"
          @click="pickFile"
          :alt="`${width}x${height}`"
          :style="`width: ${width}px; height: ${height}px; display: block`"
        />
      </a>

      <button
        v-if="(imageUrl || file)"
        @click="download"
        class="btn btn-info btn-sm mt-2 mr-2"
      >
        Download
      </button>

      <button
        v-if="(imageUrl || file) &&
        $authorization.can('service-update')"
        @click="excluded"
        class="btn btn-danger mt-2 btn-sm"
      >
        Remover
      </button>

      <input
        type="file"
        id="files"
        ref="file"
        accept="image/png"
        style="display: none"
        @change="onFilePicked"
        multiple
      />
    </div>
  </div>
</template>

<script>
import { deleteFile } from "../../services/FileService";
import { downloadFile } from "../../services/FileService";

import desktopThumbnail from "../../assets/img/desktop.png";
import mobileThumbnail from "../../assets/img/mobile.png";
import tabletThumbnail from "../../assets/img/tablet.png";

export default {
  name: "DisplayImageUpload",

  props: {
    type: {
      type: String,
      required: true
    },

    file: null,

    width: {
      type: String,
      default: "200"
    },

    height: {
      type: String,
      default: "200"
    }
  },

  data() {
    return {
      displays: {
        desktop: {
          thumbnail: desktopThumbnail,
          dimensions: "1200x280"
        },
        mobile: {
          thumbnail: mobileThumbnail,
          dimensions: "304x200"
        },
        tablet: {
          thumbnail: tabletThumbnail,
          dimensions: "712x280"
        }
      },
      loading: false,
      imageUrl: null
    };
  },

  methods: {
    download() {
      let path = this.file.path
      let fileName = this.file.name

      downloadFile(path, fileName)
        .then(res => {
          let url = res.data.url
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        })
        .catch(error => console.log(error));

    },
    pickFile() {
      this.$refs.file.value = null;
      this.$refs.file.click();
    },

    onFilePicked(event) {
      const { files } = event.target;

      if (files[0].type.includes("image")) {
        this.setImage(files[0]);
      }
    },

    validateDisplayImage(image) {
      if (Math.round(image.size / 1024) > 5000) {
        swal(
          "Tamanho inválido!",
          `A imagem deve ter o tamanho de até 5mb (Megabytes)`,
          "error"
        );

        this.imageUrl = null;
        this.$emit("changeImage", null);
        return;
      }

      this.imageUrl = URL.createObjectURL(image);

      const img = new Image();
      const vueContext = this;

      img.onload = function() {
        const { dimensions } = vueContext.displays[vueContext.type];

        if (`${this.width}x${this.height}` !== dimensions) {
          swal(
            "Dimensões inválidas!",
            `A imagem deve ter as seguintes dimensões: ${dimensions}`,
            "error"
          );

          vueContext.imageUrl = null;
          vueContext.$emit("changeImage", null);
          return;
        }

        vueContext.$emit("changeImage", image);
      };

      img.src = this.imageUrl;
    },

    setImage(image) {
      this.loading = true;
      this.validateDisplayImage(image);
      this.loading = false;
    },

    excluded() {
      if (this.file) {
        this.delete(this.file.id);
        return;
      }

      this.imageUrl = null;
      this.$emit("changeImage", null);
    },

    delete(fileID) {
      swal({
        title: "Tem certeza que deseja Deletar?",
        text: "Uma vez deletado não poderá mais recuperar esse registro.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo deletar"
      }).then(willDelete => {
        if (willDelete.value) {
          this.loading = true;

          deleteFile(fileID)
            .then(res => {
              swal("Sucesso!", "Deletado com sucesso.", "success");
              this.$emit("changeImage", null);
              this.$emit("deleteImage", null);
              this.imageUrl = null;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>